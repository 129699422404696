module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://hmnm2022.wpengine.com/graphql","schema":{"typePrefix":"Wp","timeout":1000000,"perPage":50,"requestConcurrency":10,"queryDepth":15,"circularQueryLimit":5,"previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://hmnm2022.wpengine.com/graphql"},
    },{
      plugin: require('../../emotion-cache-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
